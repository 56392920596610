import { css } from "@emotion/core"
import React, { useEffect } from "react"
import FPSidebarWidget from "./SidebarWidget"
import Chart from "chart.js"

const SkillSetWidget = () => {
  useEffect(() => {
    const ctx: HTMLCanvasElement | null = document.querySelector(
      "canvas#myChart"
    )
    ctx?.getContext("2d")

    new Chart(ctx, {
      type: "horizontalBar",
      data: {
        labels: [
          "Type/JavaScript",
          "React/Redux",
          "HTML/CSS",
          "PHP/MySQL",
          "Jetbrains IDEs",
          "Adobe Suite",
          "English",
          "Willingness",
        ],
        datasets: [
          {
            label: "",
            data: [75, 80, 85, 60, 80, 40, 70, 100],
            backgroundColor: [
              "rgba(153, 102, 255, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(250, 112, 53, 0.2)",
            ],
            borderColor: [
              "rgba(153, 102, 255, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(250, 112, 53, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        animationDuration: 300,
        legend: { display: false },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    })
  })

  return (
    <FPSidebarWidget header="Skill set">
      <div
        css={css`
          position: relative;
        `}
      >
        <canvas id="myChart" height="400" />
      </div>
    </FPSidebarWidget>
  )
}

export default SkillSetWidget

/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { FC } from "react"

export interface IProps {
  header?: string
  children?: any
  overwriteLayout?: boolean
}

const makeStyles = () => ({
  root: css`
    margin-bottom: 2rem;
  `,
})

const FPSidebarWidget: FC<IProps> = ({ header, children, overwriteLayout }) => {
  const styles = makeStyles()
  return overwriteLayout ? (
    children
  ) : (
    <div css={styles.root}>
      {header && (
        <header>
          <h4 className="widget-header">{header}</h4>
        </header>
      )}
      {children}
    </div>
  )
}

export default FPSidebarWidget
